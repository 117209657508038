import React from "react";

const OurServices = () => {
  return (
    <>
      {/* HOSTING OPTION */}
      <div className="rts-hosting-type pt--120 pb--120">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="rts-hosting-type__section text-center">
                <h3
                  className="rts-section__title"
                  // data-sal="slide-down"
                  // data-sal-delay={100}
                  // data-sal-duration={800}
                >
                  Our Services
                </h3>
                <p
                //   data-sal="slide-down"
                //   data-sal-delay={400}
                //   data-sal-duration={800}
                >
                  Comprehensive services for recharge, utility payments,
                  insurance, and more.
                </p>
              </div>
            </div>
          </div>
          {/* hosting option */}
          <div className="row">
            <div className="col-lg-12">
              <div className="rts-hosting-type__slider">
                <div className="swiper-wrapper">
                  {/* single package */}
                  <div className="swiper-slide">
                    <div className="rts-hosting-type__single">
                      <div className="hosting-icon">
                        <img src="/images/mobile-recharge-icon.png" alt="" />
                      </div>
                      <a href="/mobile-and-dth-recharge" className="title">
                        Recharge
                      </a>
                      <p className="excerpt">
                        Instant mobile and DTH recharges with secure,
                        hassle-free transactions
                      </p>

                      <a
                        href="/mobile-and-dth-recharge"
                        className="primary__btn border__btn"
                      >
                        View Details{" "}
                        <i className="fa-light fa-long-arrow-right" />
                      </a>
                    </div>
                  </div>
                  {/* single package end */}
                  {/* single package */}
                  <div className="swiper-slide">
                    <div className="rts-hosting-type__single">
                      <div className="hosting-icon">
                        <img src="/images/bill-payment-icon.png" alt="" />
                      </div>
                      <a href="/utility-bill-payment" className="title">
                        Utility Bill Payments
                      </a>
                      <p className="excerpt">
                        Easily pay utility bills online with secure and instant
                        transactions
                      </p>

                      <a
                        href="/utility-bill-payment"
                        className="primary__btn border__btn"
                      >
                        View Details{" "}
                        <i className="fa-light fa-long-arrow-right" />
                      </a>
                    </div>
                  </div>
                  {/* single package end */}
                  {/* single package */}
                  <div className="swiper-slide">
                    <div className="rts-hosting-type__single">
                      <div className="hosting-icon">
                        <img src="/images/pan-card-icon.png" alt="" />
                      </div>
                      <a href="/pan-card-service" className="title">
                        PAN Card Service
                      </a>
                      <p className="excerpt">
                        Join us as a PAN Card agent and earn while helping
                        others with applications
                      </p>

                      <a
                        href="/pan-card-service"
                        className="primary__btn border__btn"
                      >
                        View Details{" "}
                        <i className="fa-light fa-long-arrow-right" />
                      </a>
                    </div>
                  </div>
                  {/* single package end */}
                  {/* single package */}
                  <div className="swiper-slide">
                    <div className="rts-hosting-type__single">
                      <div className="hosting-icon">
                        <img src="/images/insurance-icon.png" alt="" />
                      </div>
                      <a href="/insurance" className="title">
                        Insurance
                      </a>
                      <p className="excerpt">
                        Become an insurance agent with us and earn while
                        providing valuable protection
                      </p>

                      <a href="/insurance" className="primary__btn border__btn">
                        View Details{" "}
                        <i className="fa-light fa-long-arrow-right" />
                      </a>
                    </div>
                  </div>
                  {/* single package end */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* HOSTING OPTION END */}
    </>
  );
};

export default OurServices;
