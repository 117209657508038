import React from "react";

import Footer from "../Templete/Footer";
import Header from "../Templete/Header";

const RefundPolicy = () => {
  return (
    <>
      <Header />

      <div className="rts-hosting-banner rts-hosting-banner-bg banner-default-height">
        <div className="container">
          <div className="row">
            <div className="banner-area">
              <div className="rts-hosting-banner rts-hosting-banner__content w-530">
                <span className="starting__price">Refund Policy </span>
                <h1 className="banner-title">Refund Policy</h1>
                <p className="slogan"></p>
              </div>
              <div className="rts-hosting-banner__image">
                <img src="/images/privacy-policy.png" alt="" width={550} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="rts-knowledgebase pt--120">
        <div class="container">
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade active show knowledge__content"
              id="share-hosting-tab"
              role="tabpanel"
              aria-labelledby="share-hosting"
            >
              <h3>Refund Policy</h3>
              <p>Effective Date: 01.01.2023</p>

              <h5 class="text-xl font-semibold mb-4">
                Formax It Solutions Pvt Ltd liability for refund :
              </h5>

              <p>
                1. We will refund the amount in case a transaction is failed due
                to any reason directly caused by Formax IT Solutions Pvt Ltd.
                Once Formax IT Solutions Pvt Ltd receives a relevant
                confirmation from the payment gateway, a proper refund will be
                processed to the user’s bank account and it will take 3-21
                working days to reflect from the date of transaction.
                Confirmation about the same will be notified to the User’s
                registered Email ID. Kindly note, a refund will be processed
                only for the transaction amount, and not for any payment gateway
                charges or applicable taxes.
              </p>
              <p>
                2. We will refund the amount in case the user has paid for some
                services, got confirmation about the payment but does not
                receive those services to utilize. In such cases, we request the
                user to drop us a complaint on our official Email ID and let us
                evaluate the scenario. Once we investigate and make a
                conclusion, we will refund your amount to your bank account
              </p>

              <h5 class="text-xl font-semibold mb-4 mt-8">
                Formax It Solutions Pvt Ltd non-liability for refund :
              </h5>

              <p>
                1. We will not be liable for any refund if a transaction is
                failed due to network error, electricity issues, or any other
                such reasons. Formax IT Solutions Pvt Ltd will not be
                responsible for any failure caused due to irrelevant and invalid
                reasons.
              </p>
              <p>
                2. We will not be liable for any refund after the purchase. Once
                the user agrees to use our services and conducts the payment,
                Formax IT Solutions Pvt Ltd will not be responsible for any
                refund.
              </p>
              <p class="text-slate-400 mb-10">
                2. We will not be liable for any refund if the user fails to
                perform KYC at the initial stage. Each Formax IT Solutions Pvt
                Ltd user has to go through a successful KYC verification once.
                Thus, it is mandatory to keep the correct KYC documents ready
                before initiating any payment to Formax IT Solutions Pvt Ltd.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RefundPolicy;
