import React from "react";

const OurFeatures = () => {
  return (
    <>
      {/* HOSTING FEATURE FOUR */}
      <div className="rts-hosting-feature-five pt--120 pb--120">
        <div className="container">
          <div className="section-inner">
            <div className="row g-30 justify-content-md-center align-items-center">
              <div className="col-lg-6 col-md-10 col-xl-5">
                <div className="rts-section section-style-two">
                  <div className="rts-section__two">
                    <h3 className="title mb-0">
                      What Sets Formax Pay Apart from the Competition
                    </h3>
                  </div>
                  <div className="rts-section-description">
                    <p className="description">
                      Formax Pay offers instant payments, secure transactions,
                      and 24/7 availability. With easy-to-integrate API
                      solutions, real-time tracking, and dedicated customer
                      support, we provide a seamless and reliable payment
                      experience for individuals and businesses alike.
                    </p>
                  </div>
                </div>
                <ul className="feature-list-area">
                  <li className="list-wrapper">
                    <div className="icon">
                      <img src="assets/images/feature/feature-05.svg" alt="" />
                    </div>
                    <div className="content">
                      <h4 className="title">Instant Confirmation</h4>
                      <p className="desc">
                        Enjoy real-time transaction updates, so you’re always in
                        the loop with your payments and recharges.
                      </p>
                    </div>
                  </li>
                  <li className="list-wrapper">
                    <div className="icon">
                      <img src="assets/images/feature/feature-06.svg" alt="" />
                    </div>
                    <div className="content">
                      <h4 className="title">Safe, Secure, and Reliable</h4>
                      <p className="desc">
                        Formax Pay ensures secure transactions with advanced
                        encryption and 24/7 reliability, providing a seamless
                        and trustworthy payment experience.
                      </p>
                    </div>
                  </li>
                  <li className="list-wrapper">
                    <div className="icon">
                      <img src="assets/images/feature/feature-07.svg" alt="" />
                    </div>
                    <div className="content">
                      <h4 className="title">Dedicated Support 24/7</h4>
                      <p className="desc">
                        Our dedicated support team is available around the clock
                        to assist with any queries or technical issues.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-10 col-xl-6 offset-xl-1">
                <div className="right-side-image">
                  <img
                    src="https://img.freepik.com/free-photo/happy-female-entrepreneur-with-headset-drinking-coffee-while-surfing-net-touchpad-office_637285-1983.jpg"
                    alt=""
                  />
                  <div className="image-shape-content">
                    <img
                      src="assets/images/feature/feature__image-small.png"
                      alt=""
                    />
                    <div className="img-content">
                      <h3>
                        <span className="counter">1</span>
                        <span>M+</span>
                      </h3>
                      <p>Transactions Processed Daily</p>
                      <img
                        src="assets/images/feature/crystal.svg"
                        alt=""
                        className="shape-img"
                      />
                    </div>
                  </div>
                  <img
                    src="assets/images/feature/crystal-2.svg"
                    alt=""
                    className="shape-img2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          className="feature-shape-img2"
          src="assets/images/testimonials/bg-shape-3.svg"
          alt=""
        />
        <img
          src="assets/images/feature/feature-bg-shape.svg"
          alt=""
          className="feature-shape-img"
        />
      </div>
      {/* HOSTING FEATURE FOUR END */}
    </>
  );
};

export default OurFeatures;
