import React from "react";
import { Link } from "react-router-dom";
import HeaderTop from "./HeaderTop";

const Header = (props) => {
  const headerType = props.headerType ? props.headerType : "";
  return (
    <React.Fragment>
      {/* HEADER AREA */}
      <header className="rts-header style-one header__default">
        <HeaderTop />

        <div className="container">
          <div className="row">
            <div className="rts-header__wrapper">
              {/* FOR LOGO */}
              <div className="rts-header__logo">
                <a href="/" className="site-logo">
                  <img
                    className="logo-white"
                    src="/images/white-logo.png"
                    alt="logo"
                    width={170}
                  />
                  <img
                    className="logo-dark"
                    src="/images/logo.png"
                    alt="logo"
                    width={170}
                  />
                </a>
              </div>
              {/* FOR NAVIGATION MENU */}
              <nav className="rts-header__menu" id="mobile-menu">
                <div className="hostie-menu">
                  <ul className="list-unstyled hostie-desktop-menu">
                    <li className="menu-item">
                      <a href="/" className="hostie-dropdown-main-element">
                        Home
                      </a>
                    </li>
                    <li className="menu-item">
                      <a
                        href="/about-formax-pay"
                        className="hostie-dropdown-main-element"
                      >
                        About Us
                      </a>
                    </li>

                    <li className="menu-item hostie-has-dropdown">
                      <a className="hostie-dropdown-main-element">Services</a>
                      <ul className="hostie-submenu list-unstyled menu-pages">
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href="/mobile-and-dth-recharge"
                          >
                            Mobile Recharge
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href="/mobile-and-dth-recharge"
                          >
                            DTH Recharge
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="/utility-bill-payments">
                            Utility Bill Payments
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="/pan-card-service">
                            PAN Card Service
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="/insurance">
                            Insurance
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item">
                      <a
                        href="https://docs.formaxpay.com"
                        target="_blank"
                        className="hostie-dropdown-main-element"
                      >
                        Developer API
                      </a>
                    </li>
                    <li className="menu-item">
                      <a
                        href="/contact-us"
                        className="hostie-dropdown-main-element"
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
              {/* FOR HEADER RIGHT */}
              <div className="rts-header__right">
                <a
                  href="https://app.formaxpay.com/login"
                  className="login__btn"
                  target="_blank"
                >
                  Login
                </a>
                <button
                  id="menu-btn"
                  aria-label="Menu"
                  className="mobile__active menu-btn"
                >
                  <i className="fa-sharp fa-solid fa-bars" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* HEADER AREA END */}
    </React.Fragment>
  );
};

export default Header;
