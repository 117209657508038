import React, { useContext } from "react";
import { BrowserRouter, Routes, Route, withRouter } from "react-router-dom";

import LoadingContext from "./Store/loading-context";
import Loading from "./Pages/Loading/Loading";

import Home from "./Pages/Home/Home";
import ContactUs from "./Pages/ContactUs/ContactUs";
import AboutUs from "./Pages/AboutUs/AboutUs";

import Recharge from "./Pages/OurServices/Recharge";
import UtilityBillPayments from "./Pages/OurServices/UtilityBillPayments";
import PanCardService from "./Pages/OurServices/PanCardService";
import Insurance from "./Pages/OurServices/Insurance";

import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./Pages/TermsAndConditions/TermsAndConditions";
import RefundPolicy from "./Pages/RefundPolicy/RefundPolicy";

import { FloatingWhatsApp } from "react-floating-whatsapp";

const App = () => {
  const loadingCtx = useContext(LoadingContext);

  // const routes = [
  //   { path: "/", exact: true, component: Home },
  //   { path: "/about-us", component: AboutUs },
  //   { path: "/contact-us", component: ContactUs },
  //   { path: "/pricing", component: Pricing },
  //   { path: "/transactional-sms", component: TransactionalSms },
  //   { path: "/otp-sms", component: OtpSms },
  //   { path: "/promotional-sms", component: PromotionalSms },
  //   { path: "/sms-reseller", component: SmsReseller },
  //   { path: "/sms-gateway-software", component: SmsGatewaySoftware },
  //   { path: "/privacy-policy", component: PrivacyPolicy },
  //   { path: "/terms-and-conditions", component: TermsAndConditions },
  //   { path: "/refund-policy", component: RefundPolicy },
  // ];

  return (
    <React.Fragment>
      {loadingCtx.isLoading && <Loading />}

      <FloatingWhatsApp
        phoneNumber={917890090500}
        accountName="Formax Pay"
        avatar="/images/favicon.png"
        allowEsc
        allowClickAway
        notification
        notificationSound
      />

      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="about-formax-pay" element={<AboutUs />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="mobile-and-dth-recharge" element={<Recharge />} />
          <Route
            path="utility-bill-payments"
            element={<UtilityBillPayments />}
          />
          <Route path="pan-card-service" element={<PanCardService />} />
          <Route path="insurance" element={<Insurance />} />

          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="refund-policy" element={<RefundPolicy />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
