import React from "react";

import Footer from "../Templete/Footer";
import Header from "../Templete/Header";

const ContactUs = () => {
  return (
    <>
      <Header />
      {/* shared hosting banner */}
      <div className="rts-hosting-banner rts-hosting-banner-bg banner-default-height">
        <div className="container">
          <div className="row">
            <div className="banner-area">
              <div className="rts-hosting-banner rts-hosting-banner__content w-530 contact__banner">
                <span className="starting__price">Contact Us </span>
                <h1 className="banner-title">Contact Us</h1>
                <p className="slogan">Let's Connect – We're Here to Help!</p>
              </div>
              <div className="rts-hosting-banner__image contact">
                <img
                  src="assets/images/banner/banner__contact__image.svg"
                  alt=""
                />
                <img
                  className="shape one"
                  src="assets/images/banner/banner__contact__image-sm1.svg"
                  alt=""
                />
                <img
                  className="shape two top-bottom2"
                  src="assets/images/banner/banner__contact__image-sm2.svg"
                  alt=""
                />
                <img
                  className="shape three"
                  src="assets/images/banner/banner__contact__image-sm3.svg"
                  alt=""
                />
                <img
                  className="shape four left-right2"
                  src="assets/images/banner/banner__contact__image-sm4.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* shared hosting banner end*/}
      {/* CONTACT START */}
      <section className="rts-contact-form no-bg pt--120 pb--120">
        <div className="container">
          <div className="row g-30 pb--120 justify-content-sm-center">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10">
              <div className="single-contact-method">
                <div className="method">
                  <div className="icon">
                    <i className="fa-thin fa-envelope-open" />
                  </div>
                  <div className="content">
                    <h5 className="info mb-0">
                      <a href="mailto:support@formax.cloud">
                        helpdesk@formaxpay.com <br /> partnership@formaxpay.com
                        <br />
                        nodal@formaxpay.com
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10">
              <div className="single-contact-method">
                <div className="method">
                  <div className="icon">
                    <i className="fa-thin fa-phone" />
                  </div>
                  <div className="content">
                    <h5 className="info mb-0">
                      <a href="">
                        0120-685-0678 <br /> 7011-8161-00 <br /> 7890-090-500
                        (Whatsapp Only)
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10">
              <div className="single-contact-method">
                <div className="method">
                  <div className="icon">
                    <i className="fa-thin fa-map-location-dot" />
                  </div>
                  <div className="content ">
                    <h5 className="info mb-0">
                      Formax It Solutions Pvt Ltd,
                      <br /> Ground Floor, 16, Mochpole, Near Junior School,
                      Nabapally, Barasat, Kolkata - 700126
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row gy-30 justify-content-center">
            <div className="col-xl-6 col-lg-6 col-md-10">
              <div className="contact-form">
                <div
                  className="contact-form__content"
                  //   data-sal="slide-down"
                  //   data-sal-delay={100}
                  //   data-sal-duration={800}
                >
                  <div className="contact-form__content--image">
                    <img
                      src="assets/images/contact/contact-form.png"
                      width={260}
                      height={188}
                      alt=""
                    />
                  </div>
                  <h1 className="contact-form__content--title">
                    Ask a Question
                  </h1>
                  <p className="contact-form__content--description">
                    Schedule a call today and one of our experts to help you
                    decide which service is ideal for your business and budget.
                  </p>
                  <div className="contact__shape support-page">
                    <img
                      src="assets/images/contact/contact__animated__arrow.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 offset-xl-1 col-md-10">
              <div className="form">
                <h5>Get in touch with us!</h5>
                <form className="form__content" method="post" action="#">
                  <div className="form__control">
                    <input
                      type="text"
                      className="input-form"
                      name="name"
                      id="name"
                      placeholder="Name"
                      required=""
                    />
                  </div>
                  <div className="form__control">
                    <input
                      type="text"
                      className="input-form"
                      name="phone"
                      id="phone"
                      placeholder="Mobile Number"
                      required=""
                    />
                    <input
                      type="email"
                      className="input-form"
                      name="email"
                      id="email"
                      placeholder="Email Address"
                      required=""
                    />
                  </div>
                  <textarea
                    name="message"
                    id="message"
                    cols={30}
                    rows={10}
                    placeholder="Message"
                    required=""
                    defaultValue={""}
                  />
                  <input type="checkbox" name="checkbox" id="checkbox" />
                  <label htmlFor="checkbox">
                    By submitting your information you provide written consent
                    to Formax Pay contacting you.
                  </label>
                  <button type="button" className="submit__btn">
                    Submit Now
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* CONTACT END */}
      {/* CONTACT MAP */}
      <section className="rts-contact-map-area">
        <div className="section-inner">
          <div className="contact-map-area-fluid">
            <iframe
              className="contact-map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14718.280287684298!2d88.4390018!3d22.7442159!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f899f2d9615027%3A0x8b7693dab1225dc8!2sFORMAX%20IT%20SOLUTIONS%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1725862783768!5m2!1sen!2sin"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </section>
      {/* CONTACT MAP END */}

      <Footer />
    </>
  );
};

export default ContactUs;
