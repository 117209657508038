import React from "react";
import { Link } from "react-router-dom";

const HeroBanner = () => {
  return (
    <React.Fragment>
      {/* HERO BANNER ONE */}
      <section className="rts-hero rts-hero__one banner-style-home-one">
        <div className="container">
          <div className="rts-hero__blur-area" />
          <div className="row align-items-end position-relative">
            <div className="col-lg-7">
              <div className="rts-hero__content w-550">
                {/* <h6
                // data-sal="slide-down"
                // data-sal-delay={300}
                // data-sal-duration={800}
                >
                  <img
                    src="assets/images/icon/free.png"
                    alt="free"
                    width={25}
                  />
                 
                </h6> */}
                <h3
                  className="heading"
                  style={{ color: "white" }}
                  // data-sal="slide-down"
                  // data-sal-delay={300}
                  // data-sal-duration={800}
                >
                  Revolutionizing Your Payment Experience
                </h3>
                <p
                  className="description"
                  style={{ textAlign: "justify" }}
                  // data-sal="slide-down"
                  // data-sal-delay={400}
                  // data-sal-duration={800}
                >
                  Formax Pay is a leading fintech platform offering a wide range
                  of services, including mobile recharge, utility bill payments,
                  and e-governance solutions. We also provide powerful API
                  integrations for businesses, ensuring seamless and secure
                  transactions. With 24/7 availability, real-time updates, and
                  top-notch customer support, Formax Pay simplifies digital
                  payments for both individuals and enterprises.
                </p>
                <div
                  className="rts-hero__content--group"
                  // data-sal="slide-down"
                  // data-sal-delay={500}
                  // data-sal-duration={800}
                >
                  <a
                    href="https://api.whatsapp.com/send?phone=917890900500&text=https://wa.me/917890900500?text=Hi%20Formax%Pay%20Team,%20I%20need%20some%20help."
                    target="_blank"
                    className="rts-btn btn__long white__bg"
                  >
                    Talk To Us <i class="fa-brands fa-whatsapp"></i>
                  </a>
                </div>
                {/* <p
                // data-sal="slide-down"
                // data-sal-delay={600}
                // data-sal-duration={800}
                >
                  <img src="assets/images/icon/dollar.svg" alt="" />
                  Starting from 
                </p> */}
              </div>
            </div>
            <div className="col-lg-5">
              <div className="rts-hero__images position-relative">
                <div className="rts-hero-main">
                  <div className="image-main ">
                    <img
                      className="main top-bottom2"
                      src="images/our-services.png"
                      alt=""
                    />
                  </div>
                  <img
                    className="hero-shape one"
                    src="assets/images/banner/hosting.svg"
                    alt=""
                  />
                </div>
                <div className="rts-hero__images--shape">
                  <div className="one top-bottom">
                    <img src="assets/images/banner/left.svg" alt="" />
                  </div>
                  <div className="two bottom-top">
                    <img src="assets/images/banner/left.svg" alt="" />
                  </div>
                  <div className="three top-bottom">
                    <img src="assets/images/banner/top.svg" alt="" />
                  </div>
                  <div className="four bottom-top">
                    <img src="assets/images/banner/right.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* HERO BANNER ONE END */}
    </React.Fragment>
  );
};

export default HeroBanner;
