import React, { useState } from "react";

const MobileMenu = () => {
  const [isMenu, setIsMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");

  const mobileMenuData = [
    { label: "Home", path: "/", children: null },
    {
      label: "Services",
      path: "#",
      children: [
        { label: "Mobile Recharge", path: "/mobile-recharge" },
        { label: "DTH Recharge", path: "/dth-recharge" },
        { label: "Utility Bill Payments", path: "/utility-bill-payments" },
        { label: "PAN Card Service", path: "/pan-card-service" },
        { label: "Insurance", path: "/insurance" },
      ],
    },
    {
      label: "Developer API",
      path: "https://docs.formax.cloud",
      target: "_blank",
      children: null,
    },
    {
      label: "Company",
      path: "#",
      children: [
        { label: "About Us", path: "/about-formax-pay" },
        { label: "Contact Us", path: "/contact-us" },
      ],
    },
  ];
  return (
    <React.Fragment>
      <div id="anywhere-home" className={isMenu ? `bgshow` : ``}></div>

      <div
        id="side-bar"
        className={isMenu ? `side-bar header-two show` : `side-bar header-two`}
        onClick={() => {
          setIsMenu(false);
        }}
      >
        <button
          className="close-icon-menu"
          aria-label="Close Menu"
          onClick={() => {
            setIsMenu(!isMenu);
          }}
        >
          <i className="fa-sharp fa-thin fa-xmark" />
        </button>
        {/* mobile menu area start */}
        <div className="mobile-menu-main">
          <nav className="nav-main mainmenu-nav mt--30">
            <ul className="mainmenu metismenu" id="mobile-menu-active">
              {mobileMenuData.map((e, key) => {
                return (
                  <li
                    className={
                      e.label === activeMenu
                        ? `has-droupdown mm-active`
                        : e.children !== null
                        ? `has-droupdown`
                        : ``
                    }
                    key={key}
                  >
                    <a
                      href={e.path}
                      target={e.target ? e.target : ""}
                      className="main"
                      aria-expanded={e.children ? `true` : `false`}
                      onClick={() => {
                        if (e.label === activeMenu) {
                          setActiveMenu("");
                        } else {
                          setActiveMenu(e.label);
                        }
                      }}
                    >
                      {e.label}
                    </a>

                    {e.children && (
                      <ul
                        className={
                          e.label === activeMenu
                            ? `submenu mm-collapse mm-show`
                            : `submenu mm-collapse`
                        }
                        //style={{ height: 0 }}
                      >
                        {e.children.map((ee) => {
                          return (
                            <li>
                              <a className="mobile-menu-link" href={ee.path}>
                                {ee.label}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}

              {/* <li className="has-droupdown">
              <a href="#" className="main" aria-expanded="false">
                Home
              </a>
              <ul className="submenu mm-collapse" style={{ height: 0 }}>
                <li>
                  <a className="mobile-menu-link" href="index-2.html">
                    Home One
                  </a>
                </li>
                <li>
                  <a className="mobile-menu-link" href="index-two.html">
                    Home Two
                  </a>
                </li>
              </ul>
            </li>
            <li className="has-droupdown">
              <a href="#" className="main" aria-expanded="false">
                Pages
              </a>
              <ul className="submenu mm-collapse" style={{ height: 0 }}>
                <li>
                  <a className="mobile-menu-link" href="about.html">
                    About
                  </a>
                </li>
              </ul>
            </li> */}
            </ul>
          </nav>
          <ul className="social-area-one pl--20 mt--100">
            <li>
              <a
                href="https://www.facebook.com/formaxitsolutions"
                aria-label="social-link"
                target="_blank"
              >
                <i className="fa-brands fa-facebook-f" />
              </a>
            </li>
            <li>
              <a
                href="https://x.com/formax_it"
                aria-label="social-link"
                target="_blank"
              >
                <i className="fa-brands fa-twitter" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/formax-pay"
                aria-label="social-link"
                target="_blank"
              >
                <i className="fa-brands fa-linkedin" />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/"
                aria-label="social-link"
                target="_blank"
              >
                <i className="fa-brands fa-youtube" />
              </a>
            </li>
          </ul>
        </div>
        {/* mobile menu area end */}
      </div>
    </React.Fragment>
  );
};

export default MobileMenu;
