import React from "react";

import Footer from "../Templete/Footer";
import Header from "../Templete/Header";
import SignupBanner from "../Home/SignupBanner";

const PanCardService = () => {
  return (
    <>
      <Header />

      <div className="rts-hosting-banner rts-hosting-banner-bg banner-default-height">
        <div className="container">
          <div className="row">
            <div className="banner-area">
              <div className="rts-hosting-banner rts-hosting-banner__content w-530">
                <span className="starting__price">PAN Card Service </span>
                <h1 className="banner-title">PAN Card Service</h1>
                <p className="slogan">
                  Join us as a PAN service agent and earn commissions while
                  providing essential services!
                </p>
              </div>
              <div className="rts-hosting-banner__image">
                <img src="/images/" alt="" width={550} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="rts-knowledgebase pt--120 pb--120">
        <div class="container">
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade active show knowledge__content"
              id="share-hosting-tab"
              role="tabpanel"
              aria-labelledby="share-hosting"
            >
              <h4>Become a PAN Service Agent</h4>

              <p>
                Join our growing network of PAN service agents and unlock a
                world of opportunities with Formax Pay! As a Corporate PAN
                service agent of <strong>Protean eGov Technologies Ltd.</strong>{" "}
                and{" "}
                <strong>
                  UTI Infrastructure and Technology Services Limited
                </strong>
                , we offer a rewarding platform for individuals and businesses
                to provide essential PAN card services across India.
              </p>

              <h4>Why Partner with Us?</h4>
              <ul>
                <li>
                  <strong>Attractive Commission Structure:</strong> Enjoy
                  competitive commissions based on the number of PAN cards
                  processed, ensuring a steady income stream.
                </li>
                <li>
                  <strong>Extensive Support:</strong> Benefit from comprehensive
                  training and support to help you navigate the PAN card
                  application process and provide exceptional service to your
                  clients.
                </li>
                <li>
                  <strong>High Demand Services:</strong> With over 5000 PAN
                  cards processed daily through our network, you’ll be offering
                  a service that is always in demand.
                </li>
                <li>
                  <strong>Flexible Work Environment:</strong> Operate on your
                  own terms, whether you prefer working from home or setting up
                  a dedicated office.
                </li>
                <li>
                  <strong>Established Brand:</strong> Leverage the trusted
                  reputation of Formax Pay in the fintech space to build your
                  own client base.
                </li>
              </ul>

              <h4>How to Get Started</h4>
              <ol>
                <li>
                  <strong>Application:</strong> Fill out our agent application
                  form to express your interest in becoming a PAN service agent.
                </li>
                <li>
                  <strong>Training:</strong> Attend our training sessions to
                  understand the PAN card application process, documentation
                  requirements, and our systems.
                </li>
                <li>
                  <strong>Start Operating:</strong> Once trained, you’ll receive
                  the necessary tools and resources to begin offering PAN card
                  services to your clients.
                </li>
                <li>
                  <strong>Earn Commissions:</strong> Start processing PAN card
                  applications and earn commissions for every card successfully
                  issued.
                </li>
              </ol>

              <h4>Join Us Today!</h4>
              <p>
                Become a part of our dynamic team and contribute to making PAN
                card services accessible to all. Whether you’re an entrepreneur
                looking to expand your service offerings or a business seeking
                to diversify, we have the perfect opportunity for you.
              </p>

              <p>
                <strong>
                  Contact us today to learn more about becoming a PAN service
                  agent with Formax Pay!
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <SignupBanner />
      <Footer />
    </>
  );
};

export default PanCardService;
