import React from "react";

const Testimonial = () => {
  return (
    <>
      {/* TESTIMONIAL */}
      <section className="rts-testimonial section__padding">
        <div className="container">
          <div className="row ">
            <div className="col-12 d-flex justify-content-center">
              <div className="rts-section text-center">
                <h3
                  className="rts-section__title"
                  //   data-sal="slide-down"
                  //   data-sal-delay={300}
                  //   data-sal-duration={800}
                >
                  Our Customers Love Us
                </h3>
                <p
                  className="rts-section__description"
                  //   data-sal="slide-down"
                  //   data-sal-delay={400}
                  //   data-sal-duration={800}
                >
                  Trusted by many, we deliver exceptional service and customer
                  satisfaction
                </p>
              </div>
            </div>
          </div>
          {/* testimonial */}
          <div className="row">
            <div className="col-lg-12">
              <div className="rts-testimonial__slider testimonial__slider--first">
                <div className="swiper-wrapper">
                  {/* single testimonial */}
                  <div className="swiper-slide">
                    <div className="rts-testimonial__single">
                      <div className="rating">
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                      </div>
                      <div className="content">
                        <p>
                          “Formax Pay has made managing my utility bills and
                          recharges so easy. Their platform is user-friendly and
                          quick!”
                        </p>
                      </div>
                      <div className="author__meta">
                        <div className="author__meta--image">
                          <img src="/images/user-icon.png" alt="" width={45} />
                        </div>
                        <div className="author__meta--details">
                          <a href="#">Suraj K</a>
                          <span>Formax Pay User</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* single testimonial end */}
                  {/* single testimonial */}
                  <div className="swiper-slide">
                    <div className="rts-testimonial__single">
                      <div className="rating">
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                      </div>
                      <div className="content">
                        <p>
                          “The API integration for my business was seamless. It
                          has helped streamline our transactions and improve
                          efficiency.”
                        </p>
                      </div>
                      <div className="author__meta">
                        <div className="author__meta--image">
                          <img src="/images/user-icon.png" alt="" width={45} />
                        </div>
                        <div className="author__meta--details">
                          <a href="#">Diya Singh</a>
                          <span>Formax Pay User</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* single testimonial end */}
                  {/* single testimonial */}
                  <div className="swiper-slide">
                    <div className="rts-testimonial__single">
                      <div className="rating">
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                      </div>
                      <div className="content">
                        <p>
                          “I’ve been using Formax Pay for all my mobile
                          recharges. The service is fast, and the customer
                          support is always helpful.”
                        </p>
                      </div>
                      <div className="author__meta">
                        <div className="author__meta--image">
                          <img src="/images/user-icon.png" alt="" width={45} />
                        </div>
                        <div className="author__meta--details">
                          <a href="#">Anil S</a>
                          <span>Formax Pay User</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* single testimonial end */}
                  {/* single testimonial */}
                  <div className="swiper-slide">
                    <div className="rts-testimonial__single">
                      <div className="rating">
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                        <i className="fa-solid fa-star" />
                      </div>
                      <div className="content">
                        <p>
                          “Their platform is not only reliable but also secure.
                          I’ve never had an issue with any transactions, and
                          their 24/7 service is a big advantage.”
                        </p>
                      </div>
                      <div className="author__meta">
                        <div className="author__meta--image">
                          <img src="/images/user-icon.png" alt="" width={45} />
                        </div>
                        <div className="author__meta--details">
                          <a href="#">Sneha R</a>
                          <span>Formax Pay User</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* single testimonial end */}
                </div>
                {/* pagination dot */}
                <div className="rts-dot__button slider-center" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* TESTIMONIAL END */}
    </>
  );
};

export default Testimonial;
