import React from "react";

import Footer from "../Templete/Footer";
import Header from "../Templete/Header";

const TermsAndConditions = () => {
  return (
    <>
      <Header />

      <div className="rts-hosting-banner rts-hosting-banner-bg banner-default-height">
        <div className="container">
          <div className="row">
            <div className="banner-area">
              <div className="rts-hosting-banner rts-hosting-banner__content w-530">
                <span className="starting__price">Terms & Conditions </span>
                <h1 className="banner-title">Terms & Conditions</h1>
                <p className="slogan"></p>
              </div>
              <div className="rts-hosting-banner__image">
                <img src="/images/privacy-policy.png" alt="" width={550} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="rts-knowledgebase pt--120">
        <div class="container">
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade active show knowledge__content"
              id="share-hosting-tab"
              role="tabpanel"
              aria-labelledby="share-hosting"
            >
              <h3>Terms and Conditions</h3>
              <p>Effective Date: 01.01.2023</p>
              <p>
                Welcome to the Formax Pay website. These Terms and Conditions
                govern your use of our website and services. By accessing or
                using our services, you agree to comply with and be bound by
                these Terms. If you do not agree with these Terms, please do not
                use our services.
              </p>

              <h4>1. Acceptance of Terms</h4>
              <p>
                By accessing or using our website and services, you accept these
                Terms and Conditions. If you are using the services on behalf of
                a business, you represent that you have the authority to bind
                that business to these Terms.
              </p>

              <h4>2. Definitions</h4>
              <ul>
                <li>
                  <strong>"Company"</strong> refers to Formax Pay, a division of
                  FORMAX IT SOLUTIONS PVT LTD.
                </li>
                <li>
                  <strong>"User"</strong> refers to anyone who accesses or uses
                  the website and services.
                </li>
                <li>
                  <strong>"Services"</strong> includes all services offered by
                  Formax Pay, including recharge, utility payments, and API
                  solutions.
                </li>
              </ul>

              <h4>3. Eligibility</h4>
              <p>
                You must be at least 18 years old to use our services. By using
                our services, you represent that you are at least 18 years old
                and that you have the legal capacity to enter into these Terms.
              </p>

              <h4>4. Account Registration</h4>
              <p>
                To access certain features of our services, you may need to
                create an account. You agree to:
              </p>
              <ul>
                <li>
                  Provide accurate and complete information during registration.
                </li>
                <li>Maintain the security of your password and account.</li>
                <li>
                  Notify us immediately of any unauthorized use of your account
                  or any other breach of security.
                </li>
                <li>
                  Take responsibility for all activities that occur under your
                  account.
                </li>
              </ul>

              <h4>5. Services Provided</h4>
              <p>Formax Pay offers the following services:</p>
              <ul>
                <li>Mobile Recharge</li>
                <li>DTH Recharge</li>
                <li>Utility Bill Payments</li>
                <li>API Solutions for Recharge Services</li>
              </ul>

              <h4>6. User Obligations</h4>
              <p>
                You agree to use our services only for lawful purposes and in
                accordance with these Terms. You agree not to:
              </p>
              <ul>
                <li>
                  Use the services in any way that violates any applicable law
                  or regulation.
                </li>
                <li>
                  Transmit any material that is unlawful, fraudulent, or
                  harmful.
                </li>
                <li>
                  Attempt to gain unauthorized access to our website or server.
                </li>
              </ul>

              <h4>7. Payment Terms</h4>
              <p>
                All transactions are subject to verification. Users agree to pay
                all fees and charges associated with the services at the rates
                in effect when such fees and charges are incurred. We reserve
                the right to change our fees at any time, with prior notice.
              </p>

              <h4>8. Intellectual Property</h4>
              <p>
                All content on the website, including text, graphics, logos, and
                software, is the property of Formax Pay or our licensors and is
                protected by copyright, trademark, and other intellectual
                property laws. You may not reproduce, distribute, or create
                derivative works from any content without our express written
                permission.
              </p>

              <h4>9. Limitation of Liability</h4>
              <p>
                To the fullest extent permitted by law, Formax Pay shall not be
                liable for any indirect, incidental, special, consequential, or
                punitive damages arising from or related to your use of the
                services. Our liability shall be limited to the maximum extent
                permitted by applicable law.
              </p>

              <h4>10. Indemnification</h4>
              <p>
                You agree to indemnify and hold harmless Formax Pay, its
                affiliates, and their respective officers, directors, employees,
                and agents from any claims, losses, damages, liabilities, costs,
                or expenses (including reasonable attorney's fees) arising out
                of your use of the services or any violation of these Terms.
              </p>

              <h4>11. Termination</h4>
              <p>
                We reserve the right to suspend or terminate your access to our
                services at any time, without notice, for conduct that we
                believe violates these Terms or is harmful to other users or the
                business.
              </p>

              <h4>12. Changes to Terms</h4>
              <p>
                We may update these Terms from time to time. We will notify you
                of any changes by posting the new Terms on our website. Your
                continued use of the services after any changes constitutes your
                acceptance of the revised Terms.
              </p>

              <h4>13. Governing Law</h4>
              <p>
                These Terms shall be governed by and construed in accordance
                with the laws of India. Any disputes arising out of or relating
                to these Terms shall be resolved in the courts located in
                Kolkata, India.
              </p>

              <h4>14. Contact Information</h4>
              <p>
                If you have any questions or concerns about these Terms, please
                contact us at:
              </p>
              <p>helpdesk@formaxpay.com</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
