import React from "react";

const Faq = () => {
  return (
    <>
      {/* FAQ */}
      <section className="rts-faq section__padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="rts-faq__first">
                <h3
                  className="title"
                  //   data-sal="slide-down"
                  //   data-sal-delay={300}
                  //   data-sal-duration={800}
                >
                  Got questions? Well, we've got answers.
                </h3>
                <p
                  style={{ marginBottom: "50px" }}
                  //   data-sal="slide-down"
                  //   data-sal-delay={400}
                  //   data-sal-duration={800}
                >
                  Find answers to frequently asked questions about our world
                  class messaging solutions.
                </p>
                <img
                  //   data-sal="slide-down"
                  //   data-sal-delay={500}
                  //   data-sal-duration={800}
                  src="/images/faq.png"
                  alt="faq"
                />
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1">
              <div className="rts-faq__accordion">
                <div className="accordion accordion-flush" id="rts-accordion">
                  <div
                    className="accordion-item active"
                    // data-sal="slide-left"
                    // data-sal-delay={300}
                    // data-sal-duration={800}
                  >
                    <div className="accordion-header" id="first">
                      <h4
                        className="accordion-button collapse show"
                        data-bs-toggle="collapse"
                        data-bs-target="#item__one"
                        aria-expanded="false"
                        aria-controls="item__one"
                      >
                        What services does Formax Pay offer?
                      </h4>
                    </div>
                    <div
                      id="item__one"
                      className="accordion-collapse collapse collapse show"
                      aria-labelledby="first"
                      data-bs-parent="#rts-accordion"
                    >
                      <div className="accordion-body">
                        Formax Pay provides mobile recharge, utility bill
                        payments, e-governance services, and API solutions for
                        businesses.
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item"
                    // data-sal="slide-left"
                    // data-sal-delay={400}
                    // data-sal-duration={800}
                  >
                    <div className="accordion-header" id="two">
                      <h4
                        className="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#item__two"
                        aria-expanded="false"
                        aria-controls="item__two"
                      >
                        How can I sign up for Formax Pay?
                      </h4>
                    </div>
                    <div
                      id="item__two"
                      className="accordion-collapse collapse"
                      aria-labelledby="two"
                      data-bs-parent="#rts-accordion"
                    >
                      <div className="accordion-body">
                        You can sign up by visiting our website and clicking on
                        the "Sign Up" button. Fill out the required details to
                        create your account and start using our services.
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item"
                    // data-sal="slide-left"
                    // data-sal-delay={500}
                    // data-sal-duration={800}
                  >
                    <div className="accordion-header" id="three">
                      <h4
                        className="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#item__three"
                        aria-expanded="false"
                        aria-controls="item__three"
                      >
                        What are the benefits of using Formax Pay’s API
                        solutions?
                      </h4>
                    </div>
                    <div
                      id="item__three"
                      className="accordion-collapse collapse"
                      aria-labelledby="three"
                      data-bs-parent="#rts-accordion"
                    >
                      <div className="accordion-body">
                        Our API solutions help businesses integrate our services
                        directly into their systems, streamlining processes such
                        as mobile recharge, utility payments, and more.
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item"
                    // data-sal="slide-left"
                    // data-sal-delay={600}
                    // data-sal-duration={800}
                  >
                    <div className="accordion-header" id="four">
                      <h4
                        className="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#item__four"
                        aria-expanded="false"
                        aria-controls="item__four"
                      >
                        How secure is Formax Pay?
                      </h4>
                    </div>
                    <div
                      id="item__four"
                      className="accordion-collapse collapse"
                      aria-labelledby="four"
                      data-bs-parent="#rts-accordion"
                    >
                      <div className="accordion-body">
                        We use advanced encryption and security protocols to
                        ensure all transactions and data are protected.
                      </div>
                    </div>
                  </div>

                  <div
                    className="accordion-item"
                    // data-sal="slide-left"
                    // data-sal-delay={600}
                    // data-sal-duration={800}
                  >
                    <div className="accordion-header" id="five">
                      <h4
                        className="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#item__five"
                        aria-expanded="false"
                        aria-controls="item__five"
                      >
                        How do I contact customer support?
                      </h4>
                    </div>
                    <div
                      id="item__five"
                      className="accordion-collapse collapse"
                      aria-labelledby="five"
                      data-bs-parent="#rts-accordion"
                    >
                      <div className="accordion-body">
                        You can reach our support team through the contact form
                        on our website or via email at helpdesk@formaxpay.com.
                      </div>
                    </div>
                  </div>

                  <div
                    className="accordion-item"
                    // data-sal="slide-left"
                    // data-sal-delay={600}
                    // data-sal-duration={800}
                  >
                    <div className="accordion-header" id="six">
                      <h4
                        className="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#item__six"
                        aria-expanded="false"
                        aria-controls="item__six"
                      >
                        Does Formax Pay offer 24/7 service?
                      </h4>
                    </div>
                    <div
                      id="item__six"
                      className="accordion-collapse collapse"
                      aria-labelledby="five"
                      data-bs-parent="#rts-accordion"
                    >
                      <div className="accordion-body">
                        Yes, Formax Pay operates 24/7 to provide uninterrupted
                        recharge and payment services to our users.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* FAQ END */}
    </>
  );
};

export default Faq;
