import React from "react";

import Footer from "../Templete/Footer";
import Header from "../Templete/Header";
import SignupBanner from "../Home/SignupBanner";

const Recharge = () => {
  return (
    <>
      <Header />

      <div className="rts-hosting-banner rts-hosting-banner-bg banner-default-height">
        <div className="container">
          <div className="row">
            <div className="banner-area">
              <div className="rts-hosting-banner rts-hosting-banner__content w-530">
                <span className="starting__price">Mobile & DTH Recharge </span>
                <h1 className="banner-title">Mobile & DTH Recharge</h1>
                <p className="slogan">
                  Instant mobile and DTH recharges with secure, hassle-free
                  transactions.
                </p>
              </div>
              <div className="rts-hosting-banner__image">
                <img src="/images/" alt="" width={550} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="rts-knowledgebase pt--120 pb--120">
        <div class="container">
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade active show knowledge__content"
              id="share-hosting-tab"
              role="tabpanel"
              aria-labelledby="share-hosting"
            >
              <h4>Mobile & DTH Recharge</h4>

              <h4>Overview</h4>
              <p>
                At Formax Pay, we understand the importance of staying connected
                and entertained. That’s why we offer quick and hassle-free
                mobile and DTH (Direct-to-Home) recharge services designed to
                meet your everyday needs. With just a few clicks, you can top up
                your mobile balance or recharge your DTH account, ensuring
                uninterrupted communication and entertainment.
              </p>

              <h4>Why Choose Our Mobile Recharge Service?</h4>
              <ul>
                <li>
                  <strong>Instant Recharge:</strong> Enjoy immediate processing
                  of your mobile recharge, ensuring that you never run out of
                  balance when you need it the most.
                </li>
                <li>
                  <strong>Wide Network Support:</strong> We support a wide range
                  of mobile operators, allowing you to recharge for your
                  preferred network seamlessly.
                </li>
                <li>
                  <strong>User-Friendly Interface:</strong> Our platform is
                  designed for ease of use, making it simple to navigate and
                  complete your recharge quickly.
                </li>
                <li>
                  <strong>Secure Transactions:</strong> With advanced encryption
                  and security protocols in place, you can trust that your
                  personal and payment information is protected.
                </li>
                <li>
                  <strong>24/7 Availability:</strong> Recharge your mobile
                  anytime, anywhere—our services are available round the clock
                  for your convenience.
                </li>
              </ul>

              <h4>Why Choose Our DTH Recharge Service?</h4>
              <ul>
                <li>
                  <strong>Multiple DTH Providers:</strong> Recharge for various
                  DTH service providers easily, including popular networks,
                  ensuring you can access your favorite channels without
                  interruptions.
                </li>
                <li>
                  <strong>Flexible Plans:</strong> Choose from a range of plans
                  and packages that suit your viewing preferences and budget.
                </li>
                <li>
                  <strong>Easy Payment Options:</strong> Our platform supports
                  multiple payment methods, making it convenient for you to
                  complete your DTH recharge.
                </li>
                <li>
                  <strong>Real-Time Updates:</strong> Receive instant
                  notifications confirming your recharge, so you can start
                  enjoying your content right away.
                </li>
                <li>
                  <strong>Dedicated Customer Support:</strong> Our customer
                  service team is available 24/7 to assist you with any queries
                  or issues regarding your mobile or DTH recharge.
                </li>
              </ul>

              <h4>Conclusion</h4>
              <p>
                Formax Pay is your trusted partner for mobile and DTH recharge
                services. We prioritize speed, security, and customer
                satisfaction, ensuring that you can stay connected and
                entertained without any hassle. Experience the convenience of
                quick recharges with us today!
              </p>
            </div>
          </div>
        </div>
      </div>
      <SignupBanner />
      <Footer />
    </>
  );
};

export default Recharge;
