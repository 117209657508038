import React from "react";

import Footer from "../Templete/Footer";
import Header from "../Templete/Header";
import OurFeatures from "../Home/OurFeatures";
import SignupBanner from "../Home/SignupBanner";

const AboutUs = () => {
  return (
    <>
      <Header />
      <>
        {/* shared hosting banner */}
        <div className="rts-hosting-banner rts-hosting-banner-bg banner-default-height">
          <div className="container">
            <div className="row">
              <div className="banner-area">
                <div className="rts-hosting-banner rts-hosting-banner__content about__banner">
                  <span
                    className="starting__price"
                    // data-sal="slide-down"
                    // data-sal-delay={100}
                    // data-sal-duration={800}
                  >
                    About Formax Pay
                  </span>
                  <h1
                    className="banner-title"
                    // data-sal="slide-down"
                    // data-sal-delay={200}
                    // data-sal-duration={800}
                  >
                    About Formax Pay
                  </h1>
                  <p
                    className="slogan"
                    // data-sal="slide-down"
                    // data-sal-delay={300}
                    // data-sal-duration={800}
                  >
                    Revolutionizing Your Payment Experience
                  </p>
                </div>
                <div className="rts-hosting-banner__image about">
                  <img src="/images/about-us-4.jpg" width={562} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* shared hosting banner end*/}
        {/* ABOUT RESELLER HOSTING */}
        <div className="rts-about-reseller section__padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 padding-bottom-75">
                <div className="hosting-about-image-bg" />
                <div className="hosting-about-image">
                  <div className="img-one">
                    <img
                      src="assets/images/about/resell/image-1.png"
                      width={378}
                      height={400}
                      alt=""
                    />
                  </div>
                  <div className="img-two">
                    <img
                      src="assets/images/about/resell/image-2.png"
                      width={247}
                      height={347}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="hosting-info">
                  <h3
                    className="hosting-info__title"
                    // data-sal="slide-down"
                    // data-sal-delay={200}
                    // data-sal-duration={800}
                  >
                    Who we are
                  </h3>
                  <p
                    className="description"
                    style={{ textAlign: "justify" }}
                    // data-sal="slide-down"
                    // data-sal-delay={100}
                    // data-sal-duration={800}
                  >
                    At Formax Pay, we are a forward-thinking fintech company
                    dedicated to revolutionizing the way individuals and
                    businesses handle their financial transactions. Founded on
                    the principles of innovation, reliability, and
                    customer-centricity, we provide a comprehensive suite of
                    services, including mobile recharges, utility bill payments,
                    insurance solutions, and API integrations for businesses.
                    Our user-friendly platform is designed to offer seamless and
                    secure experiences, allowing our clients to focus on what
                    matters most—connecting and growing in today’s digital
                    economy.
                  </p>
                  <h4
                    className="hosting-info__title"
                    // data-sal="slide-down"
                    // data-sal-delay={200}
                    // data-sal-duration={800}
                  >
                    Our Vision
                  </h4>
                  <p
                    className="description"
                    style={{ textAlign: "justify" }}
                    // data-sal="slide-down"
                    // data-sal-delay={100}
                    // data-sal-duration={800}
                  >
                    Our vision at Formax Pay is to create a world where
                    financial transactions are simple, efficient, and accessible
                    to everyone. We aspire to be the leading provider of digital
                    payment solutions, driving financial inclusion and
                    empowering individuals and businesses to thrive in an
                    increasingly connected world. By leveraging cutting-edge
                    technology and innovative practices, we aim to continuously
                    expand our service offerings and enhance user experiences,
                    ensuring that our clients have the tools they need to
                    navigate their financial journeys confidently.
                  </p>
                  <h4
                    className="hosting-info__title"
                    // data-sal="slide-down"
                    // data-sal-delay={200}
                    // data-sal-duration={800}
                  >
                    Our Commitment
                  </h4>
                  <p
                    className="description"
                    style={{ textAlign: "justify" }}
                    // data-sal="slide-down"
                    // data-sal-delay={100}
                    // data-sal-duration={800}
                  >
                    At Formax Pay, our commitment goes beyond just providing
                    services; we are dedicated to fostering trust and
                    reliability with our users. We prioritize security,
                    utilizing advanced encryption and technology to protect our
                    clients' data and transactions. Our customer support team is
                    available 24/7 to assist with any inquiries, ensuring a
                    smooth and supportive experience. We are committed to
                    continuous improvement, regularly seeking feedback to refine
                    our offerings and adapt to the evolving needs of our
                    customers. Together, we strive to build long-lasting
                    relationships and contribute positively to the communities
                    we serve.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ABOUT RESELLER HOSTING END */}

        <OurFeatures />

        <SignupBanner />
      </>
      <Footer />
    </>
  );
};

export default AboutUs;
