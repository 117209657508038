import React from "react";

import Footer from "../Templete/Footer";
import Header from "../Templete/Header";
import SignupBanner from "../Home/SignupBanner";

const Insurance = () => {
  return (
    <>
      <Header />

      <div className="rts-hosting-banner rts-hosting-banner-bg banner-default-height">
        <div className="container">
          <div className="row">
            <div className="banner-area">
              <div className="rts-hosting-banner rts-hosting-banner__content w-530">
                <span className="starting__price">Insurance </span>
                <h1 className="banner-title">Insurance</h1>
                <p className="slogan">
                  Become an Insurance Agent today and enjoy commissions while
                  providing essential coverage!
                </p>
              </div>
              <div className="rts-hosting-banner__image">
                <img src="/images/" alt="" width={550} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="rts-knowledgebase pt--120 pb--120">
        <div class="container">
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade active show knowledge__content"
              id="share-hosting-tab"
              role="tabpanel"
              aria-labelledby="share-hosting"
            >
              <h4>Become an Insurance Agent</h4>

              <p>
                Unlock a rewarding career as an Insurance Agent with Formax Pay!
                We are committed to providing our agents with the tools,
                training, and support necessary to succeed in the competitive
                insurance market. Join our dedicated network and become a vital
                part of a growing industry that helps clients secure their
                financial future.
              </p>

              <h4>Why Partner with Us?</h4>
              <ul>
                <li>
                  <strong>Lucrative Commission Structure:</strong> Enjoy
                  attractive commissions on each policy sold, offering you the
                  potential for significant earnings.
                </li>
                <li>
                  <strong>Comprehensive Training:</strong> Receive extensive
                  training on various insurance products, sales techniques, and
                  customer service skills to ensure you excel in your role.
                </li>
                <li>
                  <strong>Flexible Working Hours:</strong> Work at your own pace
                  and schedule, allowing for a healthy work-life balance.
                </li>
                <li>
                  <strong>Diverse Product Range:</strong> Offer clients a wide
                  array of insurance products, including health, life, and
                  property insurance, catering to their diverse needs.
                </li>
                <li>
                  <strong>Supportive Community:</strong> Join a network of
                  experienced agents and professionals who are ready to help you
                  grow and succeed.
                </li>
              </ul>

              <h4>How to Get Started</h4>
              <ol>
                <li>
                  <strong>Application:</strong> Fill out our application form to
                  express your interest in becoming an Insurance Agent.
                </li>
                <li>
                  <strong>Training:</strong> Attend our training sessions to
                  learn about insurance products, regulatory requirements, and
                  effective sales strategies.
                </li>
                <li>
                  <strong>Start Selling:</strong> Once trained, you’ll have
                  access to our range of insurance products and tools to begin
                  servicing clients.
                </li>
                <li>
                  <strong>Earn Commissions:</strong> Start earning commissions
                  for every insurance policy you sell, with ongoing
                  opportunities for growth.
                </li>
              </ol>

              <h4>Join Us Today!</h4>
              <p>
                Take the next step in your career by becoming an Insurance Agent
                with Formax Pay. Help individuals and families secure their
                future while building a successful business for yourself.
              </p>

              <p>
                <strong>
                  Contact us today to learn more about this exciting
                  opportunity!
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <SignupBanner />
      <Footer />
    </>
  );
};

export default Insurance;
